/** 语言包key值请参考vant3，网址：https://vant-contrib.gitee.io/vant/#/zh-CN/locale **/
export default {
    'zh': {
        selectNetwork:'選擇網絡',
        selectLanguage:"選擇語言",
        connect :"Connect",
        Participate:"參加",
        profitPool:"收益池",
        miningIncome:"採礦收入",
        ETHPrice:"ETH價格",
        WalletBalance:"錢包餘額",
        StakingAPY:"質押APY",
        Liquiditynode:"流動性節點",
        LiquidityPool:"流動性池",
        Participant:"參與者",
        Revenue:"收入",
        WinningRate:"得標率",
        Stake:"質押",
        LiquidityMiningRevenue:"動性採礦收入",
        Address:"地址",
        ShareNow:"立即分享",
        Home:"首頁",
        Exchange:"交換",
        Serve:"服務",
        Mine:"我的",
        retConnect:"請先連接您的錢包。",
        MultipleRevenues:"獲取多倍回報",
        StakingAmount:"質押數量",
        StakeNow:"立即質押",
        max:"最大",
        ok:"確定",

        WeNeedYou:"我們需要你！",
        MoreREWARDforyou:"你對礦池的貢獻越多，你的回報就越多",
        DAYLIMIT:"時限",
        PRIZEPOOLACTIVITY:"獎池活動",
        Earnings:"Earnings",
        Randomairdrop:"活動用戶的隨機空投。",
        Miningpoolrewards:"礦池獎勵可立即獲得。",
        USERSWHOSEWALLET1:"錢包餘額達到",
        USERSWHOSEWALLET2:"的用戶可以獲得",
        Amount:"數量",
        Output:"輸出",
        accept:"接受",

        Total:"總計",
        Exchange:"兌換",
        Exchangeratio:"兌換比率",
        Exchangeable:"可交換的",
        ExchangeQuantity:"交換數量",
        ExchangeUSDT:"兌換USDT",
        ExchangeRecords:"兌換記錄",
        ExchangeETH:"兌換ETH",
        FrequentlyAskedQuestions:"常見問題解答",

        mobileNodeMining:"ETH 新一代移動節點挖掘",
        NoNeedToTransfer:"無需轉帳",
        Stableincome:"穩定的收入",
        FreeExit:"免費退出",
        Committed:"致力於打造世界上最大的DeFi平臺",

        ProjectFeatures:"項目特點",
        Safe:"安全可靠",
        depositinyourown:"無需在您自己的錢包中轉移貨幣USDT存款0風險",
        Professional:"專業與穩定",
        Professionalteam:"專業團隊全年穩定運營",
        barrier:"低入口屏障",  
        Sharing:"共亯節點挖礦收入",
        GradingIncome:"分級收入",
        LEVEL:"等級",
        INCOME:"收入",
        Regulators:"監管機构",
        CooperationPlatform:"合作平臺",

        Withdrawable:"可提款",
        WithdrawalAmount:"提款金額",
        Withdraw:"提款",
        WithdrawalRecord:"提款記錄",
        InviteFriends:"邀請朋友",
        EarnCommission:"賺取傭金",
        Invite:"邀請",
        MyEarnings:"我的收入",
        TotalRevenue:"總收入",
        TodayEarnings:"今日收益",
        SeeAll:"查看全部",
        MyTeam:"我的團隊",
        MEMBERS:"成員",
        TotalMembers:"成員總數",
        PoolActivities:"礦池活動",
        activity:"活動",

        Pending:"處理中",
        Complete:"完成",
        reject:"拒絕",
        aiya:"哎呀！",
        noData:"暫時沒有數據",
        successful:"操作成功",
        unstake:"解押記錄",
        custody:"解押",

        nerMining:"新一代移動節點挖掘",
        copyLink:"複製連結",
        InvitationRewards:"邀請獎勵",
        oneTitle:"1.邀請並推薦您的朋友加入您的團隊，您將獲得三級獎勵。",
        twoTitle:"2.三級獎勵是",
        threeTitle:"3.每日獎勵將根據下屬的每日挖礦產量計算。",
        grade1:"級別1 - 10%",
        grade2:"級別2 - 8%",
        grade3:"級別3 - 5%",
        instans:"實例",
        instansRemark:"用戶A邀請用戶B加入。用戶B將在採礦當天產生100USDT的收入, 用户A将收到100*10%=10.00 USDT的推荐奖励.",

        Activities:"活動記錄",
        PoolActivities:"獎池活動",
        income:"收益",
        Expired:"過期",
        End:"結束",

        RevenueDetails:"收入明細",
        myTeam:"我的團隊",
        date:"日期",
        user:"使用者",

        alreadyPending:"已有一筆待處理事項",
        notice:"通知",
    },
    'en': {
        selectNetwork:'Select Network',
        selectLanguage:"Select Language",
        connect :"Connect",
        Participate:"Participate",
        profitPool:"PROFIT POOL",
        miningIncome:"Mining Income",
        ETHPrice:"ETH Price",
        WalletBalance:"Wallet Balance",
        StakingAPY:"Staking APY",
        Liquiditynode:"Liquidity node",
        LiquidityPool:"Liquidity Pool",
        Participant:"Participant",
        Revenue:"Revenue",
        WinningRate:"Winning Rate",
        Stake:"Stake",
        LiquidityMiningRevenue:"Liquidity Mining Revenue",
        Address:"Address",
        ShareNow:"Share Now",
        Home:"Home",
        Exchange:"Exchange",
        Serve:"Serve",
        Mine:"Mine",
        retConnect:"Please connect your wallet first.",
        MultipleRevenues:"Get Multiple Revenues",
        StakingAmount:"Staking Amount",
        StakeNow:"Stake Now",
        max:"Max",
        ok:"enter",
        
        WeNeedYou:"We Need You !",
        MoreREWARDforyou:"More your contribute to the mining, pool，More REWARD for you",
        DAYLIMIT:"DAY LIMIT",
        PRIZEPOOLACTIVITY:"PRIZE POOL ACTIVITY",
        Earnings:"Earnings",
        Randomairdrop:"Random airdrop of active users.",
        Miningpoolrewards:"Mining pool rewards can be received immediately.",
        USERSWHOSEWALLET1:"USERS WHOSE WALLET BALANCE REACHES",
        USERSWHOSEWALLET2:"CAN GET",
        Amount:"Amount",
        Output:"Output",
        accept:"ACCEPT",


        Total:"Total",
        Exchange:"Exchange",
        Exchangeratio:"Exchange ratio",
        Exchangeable:"Exchangeable",
        ExchangeQuantity:"Exchange Quantity",
        ExchangeUSDT:"Exchange USDT",
        ExchangeRecords:"Exchange Records",
        ExchangeETH:"Exchange ETH",
        FrequentlyAskedQuestions:"Frequently Asked Questions",

        mobileNodeMining:"ETH new generation mobile node mining",
        NoNeedToTransfer:"No need to transfer currency",
        Stableincome:"Stable income",
        FreeExit:"Free Exit",
        Committed:"Committed to building the world's largest DeFi platform integrating",

        ProjectFeatures:"Project Features",
        Safe:"Safe & secure",
        depositinyourown:"No need to transfer currency, USDT deposit in your own wallet 0 risk",
        Professional:"Professional & Stability",
        Professionalteam:"Professional team, stable operation throughout the year",
        barrier:"Low entry barrier",  
        Sharing:"Sharing node mining revenue",
        GradingIncome:"Grading Income",
        LEVEL:"LEVEL",
        INCOME:"INCOME",
        Regulators:"Regulators",
        CooperationPlatform:"Cooperation Platform",

        Withdrawable:"Withdrawable",
        WithdrawalAmount:"Withdrawal Amount",
        Withdraw:"Withdraw",
        WithdrawalRecord:"Withdrawal Record",
        InviteFriends:"Invite Friends",
        EarnCommission:"Earn Commission",
        Invite:"Invite",
        MyEarnings:"My Earnings",
        TotalRevenue:"Total Revenue",
        TodayEarnings:"Today Earnings",
        SeeAll:"See All",
        MyTeam:"My Team",
        MEMBERS:"Members",
        TotalMembers:"Total Members",
        PoolActivities:"Mining Pool Activities",
        activity:"activity",

        Pending:"Pending",
        Complete:"Complete",
        reject:"Reject",
        aiya:"Oh dear!",
        noData:"There is currently no data available",
        successful:"Operation successful",
        unstake:"Detention Record",
        custody:"Release from custody",

        nerMining:"new generation mobile node mining",
        copyLink:"Copy Link",
        InvitationRewards:"Invitation Reward",
        oneTitle:"1. Invite and refer your friends to join you will get up to three tiers of rewards.",
        twoTitle:"2. The three-tier reward is ",
        threeTitle:"3. Daily rewards will be calculated based on your subordinate's daily mining output.",
        grade1:"Level1 - 10%",
        grade2:"Level2 - 8%",
        grade3:"Level3 - 5%",
        instans:"Example",
        instansRemark:"User A invites User B to join, User B will generate 100 USDT of revenue on the day of mining, and User A will receive 100*10%=10.00 USDT referral reward.",

        Activities:"Activity Record",
        PoolActivities:"Award Pool Activities",
        income:"income",
        Expired:"Expired",
        End:"End",

        RevenueDetails:"Revenue Details",
        myTeam:"My team",
        date:"date",
        user:"user",

        alreadyPending:"There is already a pending matter",
        notice:"notice",
    },
    'ja':{
        selectNetwork:'ネットワ選択',
        selectLanguage:"言語選択",
        connect :"Connect",
        Participate:"に参加",
        profitPool:"アドレス",
        miningIncome:"採鉱収入",
        ETHPrice:"ETH価格",
        WalletBalance:"ウォレット残高",
        StakingAPY:"杭打ちAPY",
        Liquiditynode:"フローノード",
        LiquidityPool:"流動性プール",
        Participant:"参加者",
        Revenue:"収入",
        WinningRate:"落札率",
        Stake:"くい",
        LiquidityMiningRevenue:"流動性採鉱収入",
        Address:"アドレス",
        ShareNow:"今すぐ共有",
        Home:"家",
        Exchange:"スワップ",
        Serve:"サービス",
        Mine:"こう",
        retConnect:"まず財布に接続してください。",
        MultipleRevenues:"複数の収入を得る",
        StakingAmount:"質押額",
        StakeNow:"抵当に入れる",
        max:"最大値",
        ok:"を選択して、",

        WeNeedYou:"私たちはあなたが",
        MoreREWARDforyou:"鉱山プールへの貢献が多ければ多いほど、報酬が多くなります",
        DAYLIMIT:"日限",
        PRIZEPOOLACTIVITY:"プール活動",
        Earnings:"Earnings",
        Randomairdrop:"アクティブなユーザーのランダムな空中投下。",
        Miningpoolrewards:"鉱山プールの奨励金はすぐに得ることができる。",
        USERSWHOSEWALLET1:"ウォレット残高が",
        USERSWHOSEWALLET2:"に達したユーザーは、",
        Amount:"数量",
        Output:"しゅつりょく",
        accept:"受け入れる",

        Total:"合計",
        Exchange:"換算",
        Exchangeratio:"為替レート",
        Exchangeable:"交換可能",
        ExchangeQuantity:"交換数量",
        ExchangeUSDT:"交換USDT",
        ExchangeRecords:"交換レコード",
        ExchangeETH:"交換ETH",
        FrequentlyAskedQuestions:"FAQ",

        mobileNodeMining:"ETH 次世代モバイルノードマイニング",
        NoNeedToTransfer:"振替不要",
        Stableincome:"安定した収入",
        FreeExit:"無料終了",
        Committed:"世界最大のDeFiプラットフォームの構築に力を入れ",

        ProjectFeatures:"プロジェクトの特徴",
        Safe:"安全で信頼性の高い",
        depositinyourown:"お客様自身の財布にお金を移す必要はありませんUSDT預金0リスク",
        Professional:"専門性と安定性",
        Professionalteam:"専門チームは年間を通じて安定的に運営する",
        barrier:"低入口障壁",  
        Sharing:"共有ノードマイニング収益",
        GradingIncome:"等級別収益",
        LEVEL:"等級",
        INCOME:"収益",
        Regulators:"規制当局",
        CooperationPlatform:"コラボレーションプラットフォーム",

        Withdrawable:"金を出すことができる",
        WithdrawalAmount:"引き出し金額",
        Withdraw:"出金",
        WithdrawalRecord:"出金記録",
        InviteFriends:"友人を招く",
        EarnCommission:"口銭をかせぐ",
        Invite:"に勧める",
        MyEarnings:"私の収入",
        TotalRevenue:"総収入",
        TodayEarnings:"今日の収益",
        SeeAll:"すべて表示",
        MyTeam:"私のチーム",
        MEMBERS:"メンバー数",
        TotalMembers:"メンバーの数",        
        PoolActivities:"鉱池活動",
        activity:"アクティビティ",

        Pending:"ペンディング",
        Complete:"完了",
        reject:"拒否",
        aiya:"あら!",
        noData:"しばらくデータがありません",
        successful:"操作が成功しました",
        unstake:"収監解除記録",
        
        custody:"拘留解除",

        nerMining:"次世代モバイルノードマイニング",
        copyLink:"リンクのコピー",
        InvitationRewards:"招待奨励",
        oneTitle:"1.友達をチームに招待して推薦して、あなたは3級の奨励金を得ることができます。",
        twoTitle:"2.3級奨励金はい ",
        threeTitle:"3.毎日の奨励金は、部下の毎日の採掘生産量に基づいて計算されます。",
        grade1:"1レベル - 10%",
        grade2:"2レベル - 8%",
        grade3:"3レベル - 5%",
        instans:"インスタンス",
        instansRemark:"ユーザーAはユーザーBを参加させる。ユーザーBは採鉱当日に合計100USDTの収入、ユーザAは、100＊10%＝10.00USDT推奨ボーナスを取得する。",

        Activities:"アクティビティレコード",
        PoolActivities:"プール活動",
        income:"に利益を与える",
        Expired:"期限が切れる",
        End:"終了",

        RevenueDetails:"収益明細",
        myTeam:"私のチーム",
        date:"日付",
        user:"使用者",

        alreadyPending:"すでに処理すべき事項がある",
        notice:"に知らせる",
    },
    'es':{
        selectNetwork:'Seleccionar red',
        selectLanguage:"Seleccionar idioma",
        connect :"Connect",
        Participate:"Participación",
        profitPool:"Dirección",
        miningIncome:"Ingresos de minería",
        ETHPrice:"Precio de ETH",
        WalletBalance:"Cartera",
        StakingAPY:"Apilar APY",
        Liquiditynode:"Nodo de liquidez",
        LiquidityPool:"Piscina de liquidez",
        Participant:"Participantes",
        Revenue:"Ingresos",
        WinningRate:"Tasa de aceptación",
        Stake:"Pila",
        LiquidityMiningRevenue:"Ingresos mineros líquidos",
        Address:"Dirección",
        ShareNow:"Compartir ahora",
        Home:"Hogar",
        Exchange:"Intercambio",
        Serve:"Proporcionar",
        Mine:"руда",
        retConnect:"Por favor, conecte su cartera primero.",
        MultipleRevenues:"Obtener múltiples ingresos",
        StakingAmount:"Total de promesas",
        StakeNow:"Prometido ahora",
        max:"Máximo",
        ok:"Determinar",

        WeNeedYou:"¡Te necesitam",
        MoreREWARDforyou:"Cuanto más contribuyas a la mina, más recompensarás.",
        DAYLIMIT:"Límite diar",
        PRIZEPOOLACTIVITY:"Actividades de la piscina de premios",
        Earnings:"Earnings",
        Randomairdrop:"Lanzamiento aéreo aleatorio del usuario activo.",
        Miningpoolrewards:"La recompensa de la piscina minera está disponible",
        USERSWHOSEWALLET1:"Los usuarios con un saldo de cartera de",
        USERSWHOSEWALLET2:"pueden obtener",
        Amount:"Cantidad",
        Output:"Salida",
        accept:"Aceptar",
        
        Total:"Total",
        Exchange:"Cambio",
        Exchangeratio:"Proporción",
        Exchangeable:"Disponible",
        ExchangeQuantity:"Número",
        ExchangeUSDT:"Canje por USDT",
        ExchangeRecords:"Registro de intercambio",
        ExchangeETH:"Intercambio de ETH",
        FrequentlyAskedQuestions:"FAQ",

        mobileNodeMining:"ETH Nueva generación de minería de nodos móviles",
        NoNeedToTransfer:"No se requiere transferencia",
        Stableincome:"Ingresos estables",
        FreeExit:"Salida gratuita",
        Committed:"Dedicado a la plataforma defi más grande del mundo",

        ProjectFeatures:"Características del proyecto",
        Safe:"Seguridad y fiabilidad",
        depositinyourown:"No es necesario transferir dinero en su propia cartera usdt Deposit 0 Risk",
        Professional:"Especialidad y estabilidad",
        Professionalteam:"Funcionamiento estable del equipo profesional durante todo el año",
        barrier:"Barrera de entrada baja",  
        Sharing:"Ingresos procedentes de la minería en nodos compartidos",
        GradingIncome:"Ingresos clasificados",
        LEVEL:"CANTIDAD",
        INCOME:"INGRESOS",
        Regulators:"Preguntas frecuentes",
        CooperationPlatform:"Plataforma de cooperación",

        Withdrawable:"Revocable",
        WithdrawalAmount:"Importe de la retirada",
        Withdraw:"Retirada",
        WithdrawalRecord:"Registros de retiros",
        InviteFriends:"Invitar a un amigo",
        EarnCommission:"Ganar Comisiones",
        Invite:"Invitación",
        MyEarnings:"Mis ingresos",
        TotalRevenue:"Ingresos totales",
        TodayEarnings:"Ganancias de hoy",
        SeeAll:"Ver todo",
        MyTeam:"Mi equipo",
        MEMBERS:"Miembros",
        TotalMembers:"Número total de miembros",
        PoolActivities:"Actividad de la piscina minera",
        activity:"Actividades",

        Pending:"Sin resolver",
        Complete:"Completado",
        reject:"Rechazar",
        aiya:"¡Oh!",
        noData:"Por el momento no hay datos",
        successful:"Operación exitosa",
        unstake:"Registros de Liberación",
        custody:"Excarcelación",

        nerMining:"Nueva generación de minería de nodos móviles",
        copyLink:"Copiar enlace",
        InvitationRewards:"Recompensa por invitación",
        oneTitle:"1. Invite y recomiende a sus amigos a unirse a su equipo. Usted recibirá un premio de tres niveles.",
        twoTitle:"2. El tercer nivel de recompensa es",
        threeTitle:"3.La recompensa diaria se calculará en función de la producción diaria de minería de los subordinados.",
        grade1:"Nivel - 10%",
        grade2:"Nive2 - 8%",
        grade3:"Nive3 - 5%",
        instans:"Ejemplo",
        instansRemark:"El usuario a invita al usuario B a unirse. El usuario B generará 100 ingresos de USDT el día de la minería.El usuario a recibirá la recompensa recomendada de 100 * 10% = 10.00 USDT.",

        Activities:"Registro de actividades",
        PoolActivities:"Actividades del Fondo de premios",
        income:"Ingresos",
        Expired:"Caducado",
        End:"Fin",

        RevenueDetails:"Detalles de ingresos",
        myTeam:"Mi equipo",
        date:"Fecha",
        user:"Usuarios",
        alreadyPending:"Ya hay un asunto por tratar.",
        notice:"Aviso",
    },
    'ru':{
        selectNetwork:'выбор сети',
        selectLanguage:"язык",
        connect :"Connect",
        Participate:"Участие",
        profitPool:"адрес бумажника",
        miningIncome:"добычный доход",
        ETHPrice:"ETH цена",
        WalletBalance:"остаток бумажника",
        StakingAPY:"забивка сваи",
        Liquiditynode:"текучий узел",
        LiquidityPool:"бассейн текучести",
        Participant:"участник",
        Revenue:"Поступления",
        WinningRate:"коэффициент медианы",
        Stake:"свая",
        LiquidityMiningRevenue:"оборотный доход от добычи",
        Address:"адрес",
        ShareNow:"незамедлительный доступ",
        Home:"жилище",
        Exchange:"обмен",
        Serve:"служить",
        Mine:"Mineral",
        retConnect:"Сначала соедините бумажник.",
        MultipleRevenues:"Многократное вознаграждение",
        StakingAmount:"Количество залогов",
        StakeNow:"Немедленный залог",
        max:"Макс",
        ok:"Определение",

        WeNeedYou:"Ты нужна",
        MoreREWARDforyou:"Чем больше ты вносишь вклад в рудник, тем больше ты получаешь взамен",
        DAYLIMIT:"срок",
        PRIZEPOOLACTIVITY:"призовая деятельност",
        Earnings:"Earnings",
        Randomairdrop:"случайный сброс активных пользователей.",
        Miningpoolrewards:"награды за рудники можно получить сразу же.",
        USERSWHOSEWALLET1:"остаток бумажника достигает",
        USERSWHOSEWALLET2:"когда пользователь получает",
        Amount:"количество",
        Output:"выход",
        accept:"Принято",

        Total:"Общая",
        Exchange:"Обмен валюты",
        Exchangeratio:"коэффициент обмена",
        Exchangeable:"заменяемый",
        ExchangeQuantity:"количество обмена",
        ExchangeUSDT:"обмен USDT",
        ExchangeRecords:"запись Exchange",
        ExchangeETH:"обмен ETH",
        FrequentlyAskedQuestions:"FAQ",


        mobileNodeMining:"ETH копание движущихся узлов нового поколения",
        NoNeedToTransfer:"безвозмездный перевод",
        Stableincome:"стабильный доход",
        FreeExit:"бесплатный выход",
        Committed:"посвятить себя созданию крупнейшей в мире платформы DeFi, которая будет",

        ProjectFeatures:"особенности проекта",
        Safe:"надёжный",
        depositinyourown:"не нужно переводить деньги в свой кошелек",
        Professional:"специализация и стабильность",
        Professionalteam:"команда специалистов работает стабильно на протяжении всего года",
        barrier:"низкий входной барьер",  
        Sharing:"общий доход",
        GradingIncome:"ступенчатый доход",
        LEVEL:"КОЛИЧЕСТВО",
        INCOME:"ПОСТУПЛЕНИЯ",
        Regulators:"Регулирующие органы",
        CooperationPlatform:"кооперативная платформа",

        Withdrawable:"подлежащий отмене",
        WithdrawalAmount:"сумма платежа",
        Withdraw:"отступать",
        WithdrawalRecord:"запись получения денег",
        InviteFriends:"пригласить друга",
        EarnCommission:"получать комиссионные",
        Invite:"приглашать",
        MyEarnings:"мой доход",
        TotalRevenue:"общий доход",
        TodayEarnings:"доход сегодня",
        SeeAll:"все.",
        MyTeam:"Моя команда",
        MEMBERS:"член",
        TotalMembers:"Членский состав",
        PoolActivities:"Деятельность в бассейне",
        activity:"Деятельность",

        Pending:"невыясненный",
        Complete:"Завершено",
        reject:"Отказ ",
        aiya:"Ой! ",
        noData:"Пока нет данных. ",
        successful:"Операция прошла успешно",
        unstake:"Отчет об освобождении ",
        custody:"Освобождение",

        nerMining:"копание движущихся узлов нового поколения",
        copyLink:"Копировать ссылку",
        InvitationRewards:"приглашать награду",
        oneTitle:"1. приглашать и рекомендовать ваших друзей присоединиться к вашей команде, вы получите премию третьего уровня.",
        twoTitle:"2. вознаграждение три уровня ступени: ",
        threeTitle:"3.Ежедневное вознаграждение будет рассчитываться исходя из ежедневной добычи.",
        grade1:"класс 1 - 10%",
        grade2:"класс 2 - 8%",
        grade3:"класс 3 - 5%",
        instans:"пример",
        instansRemark:"пользователь A Приглашает пользователя B для участия. пользователь B будет производить в день добычи 100 USDT, пользователь A получит 100 * 10% = 10.00 USDT.",

        Activities:"Запись мероприятий",
        PoolActivities:"Деятельность & lt; & lt; Призового пула & gt; & gt; ",
        income:"Поступления",
        Expired:"Срок годности",
        End:"Конец",

        RevenueDetails:"Разбивка поступлений ",
        myTeam:"Моя команда",
        date:"Дата ",
        user:"Пользователи",
        alreadyPending:"Один вопрос остается открытым. ",
        notice:"Уведомление ",
    },
    'ko':{
        selectNetwork:'네트워크 선택',
        selectLanguage:"언어 선택",
        connect :"연결",
        Participate:"참가",
        profitPool:"지갑 주소",
        miningIncome:"채광 수입",
        ETHPrice:"ETH 가격",
        WalletBalance:"지갑 잔액",
        StakingAPY:"말뚝박기 APY",
        Liquiditynode:"유동성 노드",
        LiquidityPool:"유동성 풀",
        Participant:"참가자",
        Revenue:"수입",
        WinningRate:"낙찰률",
        Stake:"말뚝",
        LiquidityMiningRevenue:"유동성 채광 수입",
        Address:"주소",
        ShareNow:"지금 공유",
        Home:"집",
        Exchange:"교환",
        Serve:"제공",
        Mine:"광산",
        retConnect:"먼저 지갑을 연결하십시오.",
        MultipleRevenues:"두 배 이상의 수익 창출",
        StakingAmount:"질권 수량",
        StakeNow:"즉시 질권 하다",
        max:"막스",
        ok:"확인",
        
        WeNeedYou:"당신이 필요해!",
        MoreREWARDforyou:"광산에 더 많은 기여를 할수록",
        DAYLIMIT:"기한",
        PRIZEPOOLACTIVITY:"상지 행사",
        Earnings:"Earnings",
        Randomairdrop:"활성 사용자의 임의 공중 투하.",
        Miningpoolrewards:"광지 보상은 즉시 획득할 수 있습니다.",
        USERSWHOSEWALLET1:"지갑 잔액이",
        USERSWHOSEWALLET2:"에 도달한 사용자는",
        Amount:"수량",
        Output:"출력",
        accept:"Принято",

        Total:"금액",
        Exchange:"환전",
        Exchangeratio:"환전 비율",
        Exchangeable:"교환 가능",
        ExchangeQuantity:"스왑 수",
        ExchangeUSDT:"USDT 교환",
        ExchangeRecords:"교환 레코드",
        ExchangeETH:"교환 ETH",
        FrequentlyAskedQuestions:"FAQ",

        mobileNodeMining:"ETH 차세대 모바일 노드 마이닝",
        NoNeedToTransfer:"계좌이체 불필요",
        Stableincome:"안정적인 수입",
        FreeExit:"무료 종료",
        Committed:"을 연결하는 세계 최대의 DeFi 플랫폼 구축",

        ProjectFeatures:"프로젝트 특징",
        Safe:"안정성",
        depositinyourown:"자신의 지갑에서 통화 USDT 예금을 옮길 필요가 없음 0 위험",
        Professional:"전문성과 안정성",
        Professionalteam:"연중무휴 전문가 팀 운영",
        barrier:"저입구 장벽",  
        Sharing:"노드 채굴 수익 공유",
        GradingIncome:"등급별 수입",
        LEVEL:"수량",
        INCOME:"매출",
        Regulators:"규제 기관",
        CooperationPlatform:"협력 플랫폼",

        Withdrawable:"취소 가능",
        WithdrawalAmount:"인출 금액",
        Withdraw:"철수",
        WithdrawalRecord:"인출 기록",
        InviteFriends:"친구 초대",
        EarnCommission:"커미션을 벌다",
        Invite:"초대",
        MyEarnings:"내 수입",
        TotalRevenue:"총수입",
        TodayEarnings:"오늘의 수익",
        SeeAll:"모두 보기",
        MyTeam:"내 팀",
        MEMBERS:"구성원",
        TotalMembers:"총 구성원 수",
        PoolActivities:"광지 활동",
        activity:"활동",

        Pending:"미해결이었어",
        Complete:"완료",
        reject:"거부",
        aiya:"아이고!",
        noData:"데이터 없음 ",
        successful:"작업 성공",
        unstake:"해압 기록 ",
        custody:"구금 해제",

        nerMining:"차세대 모바일 노드 마이닝",
        copyLink:"링크 복사",
        InvitationRewards:"초대 보상",
        oneTitle:"1.당신의 친구를 당신의 팀에 초대하고 추천하면, 당신은 3 레벨 보상을 획득합니다.",
        twoTitle:"2.3 레벨 보상은",
        threeTitle:"3.일일 보상은 부하의 일일 채굴 생산량에 따라 계산된다.",
        grade1:"등급1 - 10%",
        grade2:"등급2 - 8%",
        grade3:"등급3 - 5%",
        instans:"인스턴스",
        instansRemark:"사용자 A는 사용자 B를 초대합니다. 사용자 B는 채굴 당일 100 USDT의 수입 사용자 A에게는 %=",

        Activities:"활동 기록",
        PoolActivities:"상지 행사",
        income:"이익",
        Expired:"만료",
        End:"끝",

        RevenueDetails:"수입 명세",
        myTeam:"내 팀",
        date:"날짜",
        user:"사용자",
        alreadyPending:"이미 처리해야 할 사항이 있다 ",
        notice:"알림",
    },
    'fi': {
        selectNetwork:'Valitse verkko',
        selectLanguage:"Valitse kieli",
        connect :"Yhdistä",
        Participate:"Osallistu",
        profitPool:"TULOSALLA",
        miningIncome:"Kaivostulot",
        ETHPrice:"ETH-hinta",
        WalletBalance:"Lompakon saldo",
        StakingAPY:"Panostus APY",
        Liquiditynode:"Likviditeettisolmu",
        LiquidityPool:"Likviditeettipooli",
        Participant:"Osallistuja",
        Revenue:"Tulot",
        WinningRate:"Voittokorkeus",
        Stake:"Panos",
        LiquidityMiningRevenue:"Likviditeettikaivostulot",
        Address:"Osoite",
        ShareNow:"Jaa nyt",
        Home:"Koti",
        Exchange:"Vaihto",
        Serve:"Palvele",
        Mine:"Minun",
        retConnect:"Yhdistä lompakkosi ensin.",
        MultipleRevenues:"Hanki useita tuloja",
        StakingAmount:"Panostusmäärä",
        StakeNow:"Panosta nyt",
        max:"Maksimi",
        ok:"enter",
        
        WeNeedYou:"Me tarvitsemme sinua!",
        MoreREWARDforyou:"Enemmän panosta kaivostoimintaan, pool, Enemmän PALAUTUS sinulle",
        DAYLIMIT:"PÄIVÄN RAJOITUS",
        PRIZEPOOLACTIVITY:"PALKINTOALAN TOIMINTA",
        Earnings:"Tulot",
        Randomairdrop:"Satunnainen ilmapudotus aktiivisista käyttäjistä.",
        Miningpoolrewards:"Kaivospoolin palkkiot voidaan saada välittömästi.",
        USERSWHOSEWALLET1:"KÄYTTÄJÄT, JOIDEN LAPSETASE SAATAA",
        USERSWHOSEWALLET2:"Voi saada",
        Amount:"Määrä",
        Output:"Tuotos",
        accept:"HYVÄKSYMINEN",


        Total:"Yhteensä",
        Exchange:"Vaihto",
        Exchangeratio:"Valuuttasuhde",
        Exchangeable:"Vaihdettavissa",
        ExchangeQuantity:"Vaihtomäärä",
        ExchangeUSDT:"Vaihda USDT",
        ExchangeRecords:"Vaihda tietueet",
        ExchangeETH:"Exchange ETH",
        FrequentlyAskedQuestions:"Usein kysytyt kysymykset",

        mobileNodeMining:"ETH:n uuden sukupolven liikkuvan solmun louhinta",
        NoNeedToTransfer:"Ei tarvitse siirtää valuuttaa",
        Stableincome:"Vakaat tulot",
        FreeExit:"Vapaa poistuminen",
        Committed:"Olemme sitoutuneet rakentamaan maailman suurimman DeFi-alustan, joka integroi",

        ProjectFeatures:"Projektin ominaisuudet",
        Safe:"Turvallinen & turvallinen",
        depositinyourown:"Ei tarvitse siirtää valuuttaa, USDT talletus omaan lompakkoon 0 riski",
        Professional:"Ammattimainen & vakaus",
        Professionalteam:"Ammattitaitoinen tiimi, vakaa toiminta ympäri vuoden",
        barrier:"Alhainen sisääntuloeste",  
        Sharing:"Solmukohtalouhintatulojen jakaminen",
        GradingIncome:"Tulojen luokittelu",
        LEVEL:"TASO",
        INCOME:"TULOT",
        Regulators:"Sääntelyviranomaiset",
        CooperationPlatform:"Yhteistyöfoorumi",

        Withdrawable:"Peruutettavissa",
        WithdrawalAmount:"Nostosumma",
        Withdraw:"Peruuta",
        WithdrawalRecord:"Poistotiedot",
        InviteFriends:"Kutsu ystäviä",
        EarnCommission:"Palkkakomitio",
        Invite:"Kutsu",
        MyEarnings:"Palkkani",
        TotalRevenue:"Tulot yhteensä",
        TodayEarnings:"Tämänpäiväinen tulos",
        SeeAll:"Katso kaikki",
        MyTeam:"Oma tiimini",
        MEMBERS:"Jäsenet",
        TotalMembers:"Jäsenet yhteensä",
        PoolActivities:"Kaivospoolin toiminta",
        activity:"toiminta",

        Pending:"Odotettavissa",
        Complete:"Täydellinen",
        reject:"Hylkää",
        aiya:"Voi luoja!",
        noData:"Tällä hetkellä tietoja ei ole saatavilla",
        successful:"Operaatio onnistui",
        unstake:"Pidätystiedot",
        custody:"Pidätyksestä vapauttaminen",

        nerMining:"uuden sukupolven liikkuvan solmun louhinta",
        copyLink:"Kopioi linkki",
        InvitationRewards:"Kutsupalkkio",
        oneTitle:"1. Kutsu ja ohjaa ystäväsi liittymään saat jopa kolme tasoa palkintoja.",
        twoTitle:"2. Kolmen tason palkkio on",
        threeTitle:"3. Päivittäiset palkkiot lasketaan alaisesi päivittäisen kaivostuotoksen perusteella.",
        grade1:"Taso 1 – 10",
        grade2:"Taso 2 – 8",
        grade3:"Taso 3 - 5",
        instans:"Esimerkki",
        instansRemark:"Käyttäjä A kutsuu Käyttäjä B liittymään, Käyttäjä B tuottaa 100 USDT tuloja kaivospäivänä ja Käyttäjä A saa 100 * 10% = 10,00 USDT suosituspalkkion.",

        Activities:"Toimintatietue",
        PoolActivities:"Palkintopoolin toiminta",
        income:"tulot",
        Expired:"Vanhentunut",
        End:"Loppu",

        RevenueDetails:"Tuloja koskevat tiedot",
        myTeam:"Joukkueeni",
        date:"päivämäärä",
        user:"käyttäjä",

        alreadyPending:"Asia on jo vireillä.",
        notice:"ilmoitus",
    }
}
