import { createApp } from 'vue'
import App from './App.vue'
import VueClipBoard from 'vue-clipboard2'
import 'vant/lib/index.css';
import scroll from 'vue-seamless-scroll/src'
const app = createApp(App)
app.use(VueClipBoard)
app.use(scroll)
// event bus
import eventBus from './utils/eventBus'
app.config.globalProperties.$eventBus = eventBus
// store
import store from './store'
app.use(store)
// router
import router from './router'
app.use(router)
// i18n
import i18n from './utils/language'
app.use(i18n)
window.t = i18n.global.t

app.mount('#app')
