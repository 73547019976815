import { createRouter, createWebHistory } from "vue-router";
import store from '../store'

const routes = [{
    path: "",
    redirect: 'index',
    component: () => import('../views/layout/Container'),
    children: [
        {
            path: '/mine',
            name: 'mine',
            component: () => import('../views/Mine.vue'),
            meta: { title: 'mine', keepAlive: true }
        },
        {
            path: '/index',
            name: 'index',
            component: () => import('../views/Home.vue'),
            meta: { title: 'index', keepAlive: true }
        }, {
            path: '/exchange',
            name: 'exchange',
            component: () => import('../views/Exchange.vue'),
            meta: { title: 'exchange', keepAlive: true }
        }, {
            path: '/server',
            name: 'server',
            component: () => import('../views/Server.vue'),
            meta: { title: 'server', keepAlive: true }
        }]
}, {
    path: "/invite",
    name: "invite",
    component: () => import('../views/invite.vue'),

}, {
    path: "/records",
    name: "records",
    component: () => import('../views/records.vue'),

}, {
    path: "/withdraws",
    name: "withdraws",
    component: () => import('../views/withdraws.vue'),

}, {
    path: "/income",
    name: "income",
    component: () => import('../views/income.vue'),

}, {
    path: "/teams",
    name: "teams",
    component: () => import('../views/teams.vue'),

}, {
    path: "/notice",
    name: "notice",
    component: () => import('../views/notice.vue'),

}, , {
    path: "/activity",
    name: "activity",
    component: () => import('../views/activity.vue'),

}]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

  
router.beforeEach((to, from, next) => {
    let allowPaths = ["/index", "/exchange", "/server", "/mine"];
    if (allowPaths.includes(to.path) || store.state.currentAccount) {
        let search=window.location.search;
        if(!to.fullPath.includes("?")){
            to.fullPath=to.fullPath+search;
        }
        next();
    } else {
        next("/index");
    }
})
export default router
