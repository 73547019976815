import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import axios from "axios";
import store from './store';
export default {
  name: 'App',
  created: function created() {
    this.init();
  },
  methods: {
    init: function init() {
      var _this = this;

      var url = "/defi/site/global";
      axios.post(url).then(function (res) {
        var item = localStorage.getItem("language");

        if (item) {
          item = JSON.parse(item);
        } else {
          item = {
            local: "en"
          };
        }

        res.data.data.banner = res.data.data.banner.filter(function (r) {
          return r.languages.includes(item.local);
        });
        store.dispatch('setGlobal', res.data.data);

        if (res.data.data.hasOwnProperty('tawk')) {
          _this.loadScript(res.data.data.tawk);
        }
      });
    },
    loadScript: function loadScript(source) {
      var script = document.createElement("script");
      script.type = "text/javascript", script.innerHTML = source, (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script);
    }
  }
};