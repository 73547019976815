import { createStore } from 'vuex'

export default createStore({
    state: {
        currentAccount: null,
        global:{
            rate:0,
            totalOutputUSDT:"",
            totalParticipants:"",
            totalValidNodes:"",
        },
        userInfo:{
            gid:'',
            lRatio:1,
            sRatio:2,
            walletBy:'',
            usdtbalance:"0.00",
            coinPlatAmount:0,
            usdtPlatAmount:0,
            stakeUsdtAmount:0,
            totalEarn:"0.00",
            todayEarnu:"0.00",
            todayEarne:"0.00",
            todayTeamu:"0.00",
            todayTeame:"0.00",
            teams:[],
            notices:[],
            activitys:[],
            whatsAppLink:"",
            lineLink:"",
            telegramLink:"",
            facebookLink:"",
            twitterLink:"",
            instagramLink:"",
            youtubeLink:"",
        }
    },
    mutations: {

    },
    actions: {
        setCurrentAccount({commit,state}, val){
            state.currentAccount = val;
        },
        setGlobal({commit,state}, val){
            state.global = val;
        },
        setUserInfo({commit,state}, val){
            state.userInfo = val;
        },
        assignUserInfo({commit,state}, obj){
            state.userInfo = Object.assign(state.userInfo,obj);
        }
    },
    modules: {
    }
})
